import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'submitButton', 'formOutput' ]
  static values = { showError: Boolean, showSuccess: Boolean, submitEnabled: Boolean }
  connect() {
    this.submitButtonTarget.disabled = this.submitEnabledValue
  }
  onPostSuccess(event) {
    this.formOutputTarget.innerHTML = 'All done! Thanks for signing up.'
    this.formOutputTarget.classList.add('success')
  }

  onPostError(event) {
    this.formOutputTarget.innerHTML = event.detail[0]['detail']
    this.formOutputTarget.classList.add('error')
  }

  onPost(event) {
    this.formOutputTarget.innerHTML = ''
    this.formOutputTarget.classList.remove('error')
    this.formOutputTarget.classList.remove('success')
  }

}
