import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['topicsList']
  static values = { url: String }
  connect() {
    const topicsList = this.topicsListTarget
    fetch(this.urlValue)
    .then( data => {
      return data.json();
    }).then( result => {
      if(result.topic_list && result.topic_list.topics) {
        const results = result.topic_list.topics.slice(0, 5)
        let list = "<ul class='discussion-content-topics-list'>"
        results.forEach(function(topic) {
          list += "<li class='discussion-content-topics-list-item'>" + topic.fancy_title + '</li>'
        })
        list += "</ul>"
        topicsList.innerHTML = list
      }
    });
  }
  onPostSuccess(event) {
  }

  onPostError(event) {
  }

  onPost(event) {
  }

}
