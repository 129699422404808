import * as u from 'JS/utils'
//Homunculus State Carousel
const nextCharacter = () => {
  let states = [];
  //Set State slugs in array
  const homunculusStates = document.querySelectorAll('.homunculus-state')
  homunculusStates.forEach(function(elem) {
    states.push(elem.dataset)
  })
  //
  //Carousel Tiles Active Classes
  let carouselTilesClasses = ['temp_left','non-active_left', 'semi-active_left', 'active',  'semi-active_right' , 'non-active_right', 'temp_right']

  //Shift classes of all in view elements towards left
  for(var i=1; i< (carouselTilesClasses.length -1); i++) {
    const element = document.querySelector(".homunculus-intro__carousel ." + carouselTilesClasses[i])
    element.classList.remove(carouselTilesClasses[i])
    element.classList.add(carouselTilesClasses[i-1])
  }
  //move templ left element to the end of container
  const carouselContainer = document.querySelector('.homunculus-intro__carousel')
  const last = document.querySelector(".homunculus-intro__carousel ." + carouselTilesClasses[0])
  carouselContainer.appendChild(last)
  //add temp right class(for transform animation to happen)
  last.classList.remove(carouselTilesClasses[0])
  last.classList.add(carouselTilesClasses[carouselTilesClasses.length -1])
  last.classList.remove(carouselTilesClasses[carouselTilesClasses.length -1])
  last.classList.add(carouselTilesClasses[carouselTilesClasses.length -2])

  //Shift state
  var out = states.shift()
  states.push(out)

  var current = document.querySelector('.homunculus-intro__state-images.active').dataset.ref;
  //Change Current Links Header
  const el = Array.prototype.slice.call(homunculusStates).find(function(element) {
    return element.dataset.state == current
  })
  const currentState = document.querySelector('.homunculus-states-container [data-state="' + current + '"]')
  const carouselTitle = document.querySelector('.homunculus-carousel-links__current')
  const newTitle = el.querySelector('.homunculus-state__title')
  carouselTitle.innerHTML = newTitle.innerHTML
  const newStrapLine = el.querySelector('.strapline-tobe')
  document.querySelector('.homunculus-intro__state-strapline').innerHTML = newStrapLine.innerHTML
  //Change State Strapline in intro
  //Hide previous State Content
  document.querySelector('.homunculus-state.active').classList.remove("active");
  //Show current State content
  el.classList.add("active");
  const bottle = document.querySelector('.homunculus-state.active .homunculus-state__main__cocktail-image .fill')
  var inview = new Waypoint({
    element: bottle,
    handler: function(direction) {
      bottle.classList.add('animated')
    },
    offset: '75%'
  })
}


const prevCharacter = () => {
  let states = [];
  //Set State slugs in array
  const homunculusStates = document.querySelectorAll('.homunculus-state')
  homunculusStates.forEach(function(elem) {
    states.push(elem.dataset)
  })
  //
  //Carousel Tiles Active Classes
  let carouselTilesClasses = ['temp_left','non-active_left', 'semi-active_left', 'active',  'semi-active_right' , 'non-active_right', 'temp_right']
  //Move towards right all in view elements starting form right
  for(var i=carouselTilesClasses.length -2; i>0; i--) {
    const element = document.querySelector(".homunculus-intro__carousel ." + carouselTilesClasses[i])
    element.classList.remove(carouselTilesClasses[i])
    element.classList.add(carouselTilesClasses[i+1])
  }
  //Move hidden temp right element in the front
  const carouselContainer = document.querySelector('.homunculus-intro__carousel')
  const first = document.querySelector(".homunculus-intro__carousel ." + carouselTilesClasses[carouselTilesClasses.length -1])
  carouselContainer.prepend(first)
  //add temp right class(for transform animation to happen)
  first.classList.remove(carouselTilesClasses[carouselTilesClasses.length -1])
  first.classList.add(carouselTilesClasses[0])
  first.classList.remove(carouselTilesClasses[0])
  first.classList.add(carouselTilesClasses[1])

  //Un-Shift state
  var out = states.shift()
  states.push(out)
  var current = document.querySelector('.homunculus-intro__state-images.active').dataset.ref;

  const el = Array.prototype.slice.call(homunculusStates).find(function(element) {
    return element.dataset.state == current
  })
  const currentState = document.querySelector('.homunculus-states-container [data-state="' + current + '"]')
  const carouselTitle = document.querySelector('.homunculus-carousel-links__current')
  const newTitle = el.querySelector('.homunculus-state__title')
  carouselTitle.innerHTML = newTitle.innerHTML
  const newStrapLine = el.querySelector('.strapline-tobe')
  document.querySelector('.homunculus-intro__state-strapline').innerHTML = newStrapLine.innerHTML
  //Change State Strapline in intro
  //Hide previous State Content
  document.querySelector('.homunculus-state.active').classList.remove("active");
  //Show current State content
  el.classList.add("active");
  const bottle = document.querySelector('.homunculus-state.active .homunculus-state__main__cocktail-image .fill')
  var inview = new Waypoint({
    element: bottle,
    handler: function(direction) {
      bottle.classList.add('animated')
    },
    offset: '75%'
  })
}

u.domReady(function() {
  const carousel = document.querySelector('.homunculus-intro__carousel')
  if(typeof(carousel) != 'undefned' && carousel != null) {
  let states = [];
  //Set State slugs in array
  const homunculusStates = document.querySelectorAll('.homunculus-state')
  homunculusStates.forEach(function(elem) {
    states.push(elem.dataset)
  })
  //
  //Carousel Tiles Active Classes
  let carouselTilesClasses = ['temp_left','non-active_left', 'semi-active_left', 'active',  'semi-active_right' , 'non-active_right', 'temp_right']


  //Set initial current slug and state content
  const initialCurrent = document.querySelector('.homunculus-intro__state-images.active').dataset.ref;
  const el = Array.prototype.slice.call(homunculusStates).find(function(element) {
    return element.dataset.state == initialCurrent
  })
  const text = el.querySelector('.homunculus-state__title')
  const strapLine = el.querySelector('.strapline-tobe')
  document.querySelector('.homunculus-intro__state-strapline').innerHTML = strapLine.innerHTML

  el.classList.add("active")


  //Carousel Next
  const nextButton = document.querySelector('.homunculus-carousel-links .next')
  if(nextButton) {
    nextButton.addEventListener('click', nextCharacter, false)
  }
  const prevButton = document.querySelector('.homunculus-carousel-links .prev')
  if(prevButton) {
    prevButton.addEventListener('click', prevCharacter, false)
  }

  //Carousel Previous
  }
})
