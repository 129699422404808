import Swiper, { Pagination, Navigation } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  autoHeight: true,
  spaceBetween: 20,
  navigation: {
    nextEl: '.swiper-button-next--hp-hl',
    prevEl: '.swiper-button-prev--hp-hl',
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300)
    },
  },
  breakpoints: {
    600: {
      slidesPerView: 2,
      centeredSlides: false
    },
    1055: {
      spaceBetween: 0,
      slidesPerView: 3
    },
  }
}

document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.getElementById('swiper-drugs')
  var mySwiper = new Swiper(swiperContainer, swiperOptions);
});
