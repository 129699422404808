// import * as u from 'JS/utils'

document.addEventListener("turbolinks:load", function() {
    const accordions = document.querySelectorAll('.learning-zone__accordion');

    accordions.forEach(accordion => {
        accordionControl(accordion);
    });
});

const accordionControl = function(accordion) {
    console.log('accordion', accordion);

    if (accordion) {
        const items = accordion.querySelectorAll('.learning-zone__accordion-item');
        const media = accordion.querySelector('.learning-zone__accordion-media');
        let images;

        if (media) {
            images = media.querySelectorAll('.learning-zone__accordion-media-image');
        }

        items.forEach(item => {
            const index = item.dataset.index;
            const header = item.querySelector('.learning-zone__accordion-item-header');
            const content = item.querySelector('.learning-zone__accordion-item-content');
            let image;

            if (media && images) {
                image = images[index];
            }

            header.addEventListener('click', () => {
                item.classList.add('active');

                if (media && images && image) {
                    image.classList.add('active');
                    
                    images.forEach(img => {
                        if (img !== images[index]) {
                            img.classList.remove('active');
                        }
                    });
                }

                items.forEach(otherItem => {
                    if (otherItem !== item) {
                        otherItem.classList.remove('active');
                    }
                });
            });
        });
    }
};
  