import Swiper, { Pagination, Navigation } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: false,
  loop: true,
  autoHeight: true,
  spaceBetween: 35,
  navigation: {
    nextEl: '.swiper-button-next__hp-nav',
    prevEl: '.swiper-button-prev__hp-nav',
  },
  pagination: {
    el: '.hero-resource-nav-bullets',
    type: 'bullets',
    bulletClass: 'swiper-pagination-bullet',
    bulletActiveClass: 'swiper-pagination-bullet-active',
  },
  breakpoints: {
    978: {
      slidesPerView: 2,
      spaceBetween: 30,
      centeredSlides: false,
      loop: true,
      autoHeight: true,
    },
    1500: {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: false,
      loop: true,
      autoHeight: true,
    }
  },
  on: { // below is to fix bad width calculated for slides
    init() {

      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },
}
document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.getElementById('hero-resource-nav')
  const items = document.getElementsByClassName('hero-resource-nav-list_item')
  const activeIndex = Array.prototype.findIndex.call(items, item => item.classList.contains('hero-resource-nav-list_item--active'));
  //if (activeIndex !== -1) {
  //  swiperOptions.initialSlide = activeIndex;
  //}
  if(items.length > 1) {
    new Swiper(swiperContainer, swiperOptions); 
    //setTimeout(function() { new Swiper(swiperContainer, swiperOptions); }, 500)
  }
});