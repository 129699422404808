import Swiper, { Pagination, Navigation } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  navigation: {
    nextEl: '.swiper-button-next__hp-nav',
    prevEl: '.swiper-button-prev__hp-nav',
  },
  pagination: {
    el: '.homepage_main_navgiation-list--nav',
    type: 'bullets',
    bulletClass: 'homepage_main_navgiation-list--nav--bullet',
    bulletActiveClass: 'homepage_main_navgiation-list--nav--bullet__active',
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },
}
document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.getElementById('homepage_main_navigation--swiper-navigation')
  const items = document.getElementsByClassName('homepage_main_navgiation-list_item')
  if(items.length > 1) {
    setTimeout(function() { new Swiper(swiperContainer, swiperOptions); }, 500)
  }
});
