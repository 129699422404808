document.addEventListener("turbolinks:load", function() {
  const timeline = document.getElementById('history');
  const footer = document.getElementById('footer');
  const content = document.querySelector(".history__content");

  if (timeline !== null && footer !== null && content !== null) {
    if (window.innerWidth >= 1184 ) {
      window.onscroll = function () {
        let scroll = document.documentElement.scrollTop || document.body.scrollTop
        
        if (scroll >= timeline.clientHeight - footer.clientHeight) {
          content.classList.add("content-visible");
        } else if (scroll < timeline.clientHeight - footer.clientHeight) {
          content.classList.remove("content-visible");
        }
      };
    }
  }
});