const { element } = require("prop-types");

const _OUT = document.getElementsByClassName("stat");

document.addEventListener("turbolinks:load", function() {
  setTimeout(function(){
    Array.from(_OUT).forEach(function(stat) {
      const _LENGTH = stat.classList.length - 1;
      const _STAT = stat.classList[_LENGTH];
      stat.style.setProperty('--p', _STAT)
    });
  }, 1500);

  const stats = document.querySelectorAll(".stats-input");
  stats.forEach(element => {
    element.addEventListener("click", function() {
      Array.from(_OUT).forEach(function(stat) {
        stat.style.setProperty('--p', 0)
      });

      setTimeout(function(){
        Array.from(_OUT).forEach(function(stat) {
          const _LENGTH = stat.classList.length - 1;
          const _STAT = stat.classList[_LENGTH];
          stat.style.setProperty('--p', _STAT)
        });
      }, 200);
    })
  });
})


if (window.CSS && CSS.registerProperty) {
  CSS.registerProperty({
    name: "--p",
    syntax: '<integer>',
    initialValue: 0,
    inherits: true
  })
}
