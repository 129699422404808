// import * as u from 'JS/utils'
import Swiper, { Pagination, Navigation } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

const swiperOptions = {
  slidesPerView: 1,
  // width: 400,
  // centeredSlides: true,
  loop: true,
  loopAdditionalSlides: 1,
  spaceBetween: 16,
  watchOverflow: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    800: {
      slidesPerView: 3,
      spaceBetween: 32,
      loop: false,
      loopAdditionalSlides: 0,
    },
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },
}
document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.querySelectorAll('.learning-zone__carousel-slider')

  if(swiperContainer.length > 0) {
    swiperContainer.forEach(function(container) {
      const items = container.querySelectorAll('.learning-zone__carousel-item')
      if(items.length > 1) {
        setTimeout(function() { new Swiper(container, swiperOptions); }, 500)
      }
    })
  }

});
