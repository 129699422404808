import Swiper, { Pagination, Navigation } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-button-next--hp-hl',
    prevEl: '.swiper-button-prev--hp-hl',
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },
  breakpoints: {
    // when window width is >= 320px
    978: {
      slidesPerView: 1.5
    },
  }
}

document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.getElementById('swiper-highlights')
  var mySwiper = new Swiper(swiperContainer, swiperOptions);
});
