import * as u from 'JS/utils'

u.domReady(function() {
  const adviceItems = document.getElementsByClassName("accordion-item");

  if(adviceItems.length > 0) {
    adviceItems[0].classList.add('active');
  }

  Array.from(adviceItems).forEach(function(item) {
    item.children[0].addEventListener("click", function(e) {
      closeAllItems(adviceItems);
      item.classList.toggle('active');
    })
  });
});

const closeAllItems = function(elements) {
  Array.from(elements).forEach(function(el) {
    el.classList.contains('active') ? el.classList.remove('active') : null
  });
}

