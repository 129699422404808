import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

// configure Swiper to use modules
Swiper.use([Pagination]);

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  autoHeight: true,
  pagination: {
    el: '.testimonial-carousel__nav',
    type: 'bullets',
    bulletClass: 'testimonial-carousel__nav__bullet',
    bulletActiveClass: 'testimonial-carousel__nav__bullet--active',
    clickable: true,
  },
  autoplay: {
    delay: 10000,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },

}

document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.getElementById('swiper-testimonials')

  var mySwiper = new Swiper(swiperContainer, swiperOptions);
});
