import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }
  playVideo(e) {
    e.preventDefault()
    const src = `https://player.vimeo.com/video/${this.element.dataset.videoId}`
    const overlay = document.getElementById('video-player-lightbox').content.cloneNode(true)
    const iframe = overlay.querySelector('iframe')
    iframe.src = src
    const body = document.querySelector('body')
    body.append(overlay)
  }
  closeVideo(e) {
    this.element.remove()
  }
}
